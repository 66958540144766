.panel_innerorbit
{
    margin-top: 5px;
    margin-bottom: 5px;
}

.panel_innerorbit .panel_hero
{
    background-color: #08f;
    background-size: cover;
    background-image: url('./astronaut-spacewalk-iss-tools.jpg');
    background-repeat: no-repeat;
    background-position: 100% 100%;
    transition: background-position 3s;
    padding-top: 100px;
}

.panel_innerorbit .panel_hero .textGradient
{
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 100, 0.7));
    width: 100%;
    height: 100%;
    padding-top: 100px;
    padding-bottom: 80px;
}

.panel_innerorbit .panel_hero:hover
{
    background-position: 100% 90%;
}


.panel_innerorbit .panel_hero .panel_heading
{
    text-align: left;
    float: right;
    margin-right: 40px;
}

.panel_innerorbit .panel_hero .panel_heading .panel_left,
.panel_innerorbit .panel_hero .panel_heading .panel_right
{
    float: left;
}

.panel_innerorbit .panel_hero .logo_innerorbit
{
    width: 250px;
    height: auto;
}

.panel_innerorbit .panel_hero .panel_heading h1
{
    font-family: courgette, arial, sans-serif;
    color: #a62082;
    font-weight: 600;
    font-size: 54px;
}

.panel_innerorbit .panel_hero .panel_heading h1 span
{
    color: #ff59a2;
}

.panel_innerorbit .panel_hero .panel_heading h2
{
    color: #fff;
}

.panel_innerorbit .project-link
{
    color: #f08;
}

.panel_innerorbit .project-details .logo_innerorbit
{
    width: 200px;
}

.panel_innerorbit .project-summary
{
    padding-top: 20px;
}

@media only screen and (max-width: 500px) {

    .panel_innerorbit
    {
        margin-top: 0;
        margin-bottom: 0;
    }

    .panel_innerorbit .panel_hero,
    .panel_innerorbit .panel_hero .textGradient
    {
        padding-top: 50px;
    }

    .panel_innerorbit .panel_hero .panel_heading
    {
        float: none;
        text-align: center;
        margin: 0;
    }

    .panel_innerorbit .panel_hero .panel_heading .panel_left,
    .panel_innerorbit .panel_hero .panel_heading .panel_right
    {
        float: none;
    }

    .panel_innerorbit .panel_hero .panel_heading h1
    {
        font-size: 42px;
    }

    .panel_innerorbit .panel_hero .panel_heading h2
    {
        font-size: 20px;
    }

    .panel_innerorbit .panel_hero .logo_innerorbit
    {
        width: 200px;
    }

    .panel_innerorbit .project-details .logo_innerorbit
    {
        display: none;
    }

    .panel_innerorbit .project-summary
    {
        padding-top: 0;
    }
}