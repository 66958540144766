body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



a
{
  color: #08f;
  text-decoration: none;
}

ul li
{
  list-style: none;
}


.skew-panel
{
    position: relative;
    padding-bottom: 20px;
}


.skew-background
{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: skewY(3deg);
            transform: skewY(3deg);
    -webkit-transform-origin: top right;
            transform-origin: top right
}


.skew-background-reverse
{
    -webkit-transform: skewY(-3deg);
            transform: skewY(-3deg);
    -webkit-transform-origin: top left;
            transform-origin: top left;
}

@media only screen and (max-width: 500px) {

    .skew-background
    {
        -webkit-transform: skewY(6deg);
                transform: skewY(6deg);
    }
}


.content-width
{
  max-width: calc(1024px - 8px);
  margin-left: auto;
  margin-right: auto;
  padding-left: 4px;
  padding-right: 4px;
}


.verticalPadding { padding-top: 40px; padding-bottom: 40px; }

.float { float: left; }
.float-right { float: right; }
.clear { clear: both; }

.relative { position: relative; }

.nowrap { white-space: nowrap; }

.text-shadow { text-shadow: 1px 1px 8px #222; }
body
{
    color: #223;
    font-family: Roboto, Arial, sans-serif;
    text-align: left;
}

header
{
    padding-top: 200px;
    text-align: center;
    height: 400px;
    background: linear-gradient(90deg, #9d9cc7, #ade6f7);

}

header h1
{
    color: #fff;
    margin-top: 20px;
    font-size: 42px;
    font-weight: normal;
    font-family: Merriweather, Courgette, Arial, serif;
}

header h1 span
{
   color: #b9ebff;
}

header .tagline
{
    color: #445;
    font-weight: bold;
    letter-spacing: 1px;

}

header .location
{
    font-family: Handlee, Arial, sans-serif;
    margin-top: 4px;
    color: #fff;
    font-weight: bold;
}

@media only screen and (max-width: 500px) {

    header h1
    {
        font-size: 38px;
    }

    header .tagline
    {
        font-size: 14px;
    }
}



h1, h2, h3, h4
{
    margin-top: 0;
    margin-bottom: 0;
}

p
{
    margin-top: 10px;
    margin-bottom: 10px;
}


button
{
  color: #fff;
  border: 0;
  font-weight: bold;
  text-align: center;
  border-radius: 4px;
  font-family: Roboto, Arial, sans-serif;
  background-color: #08f;
  padding: 8px 12px;
  outline: 0;
  cursor: pointer;
}


.cta
{
  margin-top: 10px;
  font-size: 24px;
  font-family: Asap, Robot, Arial, sans-serif;
  box-shadow: 1px 1px 4px #888888;
  padding: 12px 16px;
  border-radius: 10px;
  transition: background-color 0.5s, border-radius 0.5s, box-shadow 0.5s;
}

.cta:hover
{
    border-radius: 16px;
    box-shadow: #222222 1px 1px 4px;
}

.button2
{
    background-color: #08f;
}

.button3
{
    background-color: #f80;
}

.button4
{
    background-color: #52d0c4;
}

button.button3.cta:hover
{
    /* background-color: #ff6a00; */
}


@media only screen and (max-width: 500px) {

    .cta
    {
        font-size: 18px;
    }
}



.panel_navigation
{
    padding-top: 2px;
    padding-bottom: 20px;
    display: none;
}



.list_navigation li
{
    float: left;
}

.list_navigation li a
{
    color: #fff;
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 2px;
    padding-top: 4px;
    text-align: center;
    font-family: Asap, Arial, sans-serif;
    font-weight: bold;

}

.list_navigation li a.active
{
    border-bottom: 2px solid #bce6fd
}

.panel_footer
{
    padding-top: 20px;
    background-color: #50585f;
    text-align: center;
}

.panel_footer a
{
    color: #c1e2ff;
}

.panel_copyright
{
    margin-top: 20px;
    background-color: #444c52;
    padding: 12px 0;
    font-size: 12px;
}

@media only screen and (max-width: 500px) {

    .panel_footer
    {
        padding-top: 0;
        margin-top: 0;
    }

    .panel_footer h1
    {
        font-size: 22px;
    }
}



/* projects */

.panel_project
{
    width: calc(100% - 40px);
    margin-right: 20px;
    margin-left: 20px;
    transition: box-shadow 1s;
}



.panel_project:hover
{
    box-shadow: #999 1px 1px 6px;
}

.project-details
{
    float: left;
    width: 55%;
    margin-bottom: 40px;
    text-align: right;
}

.project-summary
{
    float: right;
    width: 40%;
    margin-top: 10px;
}

.project-summary p
{
    margin-bottom: 24px;
}

.project-link
{
    margin-top: 10px;
    font-size: 18px;
}


@media only screen and (max-width: 500px) {

    .panel_project
    {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }


    .project-details,
    .project-summary
    {
        text-align: center;
        width: 75%;
        margin-left: auto;
        margin-right: auto;
        float: none;
    }

    .project-details
    {
        padding-top: 10px;
    }

    .project-summary
    {
        padding-bottom: 30px;
    }


}



/* colors */

.contentBox-periwinkle
{
    color: #888;
    background-color: #ecf7ff;
}

.contentBox-pink
{
    background-color: #f6546c;
}

.contentBox-grape
{
    background-color: #7861ea;
}

.contentBox-slate
{
    background-color: #aec1d2;
}

.white
{
    color: #fff;
}
.panel_rocketlit .panel_hero
{
    background-color: #08f;
    background-size: cover;
    background-image: url(/static/media/reading-on-the-roof.69c6875b.jpg);
    background-repeat: no-repeat;
    background-position: center 15%;
    min-height: 600px;
    text-align: left;
    transition: background-position 3s;
}

.panel_rocketlit .panel_hero:hover
{
    background-position: center 50%;
}


.panel_rocketlit .panel_hero .logo_rocketlit
{
    margin-top: 40px;
    width: 500px;
    height: auto;
}

.panel_rocketlit .panel_hero h1
{
    font-family: Asap, Arial, sans-serif;
    font-size: 42px;
    letter-spacing: -1px;
    color: #3d4260;
    text-shadow: #e9f0f8 2px 2px 4px;
    max-width: 540px;
}

.panel_rocketlit .project-link
{
    color: #f9bd86;
}

.panel_rocketlit .logo
{

}

@media only screen and (max-width: 500px) {

    .panel_rocketlit .panel_hero,
    .panel_rocketlit .panel_hero:hover
    {
        min-height: 325px;
        text-align: center;
        background-position: 50% 50%;
    }

    .panel_rocketlit .panel_hero h1
    {
        font-size: 22px;
        width: 60%;
        margin-left: auto;
        margin-right: auto;

    }

    .panel_rocketlit .panel_hero .logo_rocketlit
    {
        width: 250px;
    }

    .panel_rocketlit .project-details .logo_rocketlit
    {
        display: none;
    }
}
.panel_innerorbit
{
    margin-top: 5px;
    margin-bottom: 5px;
}

.panel_innerorbit .panel_hero
{
    background-color: #08f;
    background-size: cover;
    background-image: url(/static/media/astronaut-spacewalk-iss-tools.1b7a6f1a.jpg);
    background-repeat: no-repeat;
    background-position: 100% 100%;
    transition: background-position 3s;
    padding-top: 100px;
}

.panel_innerorbit .panel_hero .textGradient
{
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 100, 0.7));
    width: 100%;
    height: 100%;
    padding-top: 100px;
    padding-bottom: 80px;
}

.panel_innerorbit .panel_hero:hover
{
    background-position: 100% 90%;
}


.panel_innerorbit .panel_hero .panel_heading
{
    text-align: left;
    float: right;
    margin-right: 40px;
}

.panel_innerorbit .panel_hero .panel_heading .panel_left,
.panel_innerorbit .panel_hero .panel_heading .panel_right
{
    float: left;
}

.panel_innerorbit .panel_hero .logo_innerorbit
{
    width: 250px;
    height: auto;
}

.panel_innerorbit .panel_hero .panel_heading h1
{
    font-family: courgette, arial, sans-serif;
    color: #a62082;
    font-weight: 600;
    font-size: 54px;
}

.panel_innerorbit .panel_hero .panel_heading h1 span
{
    color: #ff59a2;
}

.panel_innerorbit .panel_hero .panel_heading h2
{
    color: #fff;
}

.panel_innerorbit .project-link
{
    color: #f08;
}

.panel_innerorbit .project-details .logo_innerorbit
{
    width: 200px;
}

.panel_innerorbit .project-summary
{
    padding-top: 20px;
}

@media only screen and (max-width: 500px) {

    .panel_innerorbit
    {
        margin-top: 0;
        margin-bottom: 0;
    }

    .panel_innerorbit .panel_hero,
    .panel_innerorbit .panel_hero .textGradient
    {
        padding-top: 50px;
    }

    .panel_innerorbit .panel_hero .panel_heading
    {
        float: none;
        text-align: center;
        margin: 0;
    }

    .panel_innerorbit .panel_hero .panel_heading .panel_left,
    .panel_innerorbit .panel_hero .panel_heading .panel_right
    {
        float: none;
    }

    .panel_innerorbit .panel_hero .panel_heading h1
    {
        font-size: 42px;
    }

    .panel_innerorbit .panel_hero .panel_heading h2
    {
        font-size: 20px;
    }

    .panel_innerorbit .panel_hero .logo_innerorbit
    {
        width: 200px;
    }

    .panel_innerorbit .project-details .logo_innerorbit
    {
        display: none;
    }

    .panel_innerorbit .project-summary
    {
        padding-top: 0;
    }
}
.panel_wpromote .panel_hero
{
    background-color: #08f;
    background-size: cover;
    background-image: url(/static/media/wpromote-blog-content.e32e001b.png);
    background-repeat: no-repeat;
    background-position: center 10%;
    min-height: 600px;
    text-align: left;
    transition: background-position 3s;
}

.panel_wpromote .panel_hero:hover
{
    background-position: center 50%;
}

.panel_wpromote .project-link
{
    color: #ffc5ef;
}

.contentBox-pink
{
    background-color: #fd8484;
}

.panel_projects
{
    padding-top: 40px;
}

.panel_bio
{
    margin-top: 40px;
}

.panel_bio .bio
{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 60px;
    padding-bottom: 60px;
}

.panel_bio .bio h1
{
    font-family: Handlee, arial, sans-serif;
}

.panel_bio .bio h1 .name,
.panel_bio .bio h2 strong
{
    color: #ff6ee4;
}

.panel_bio .bio h1 strong
{

}

.panel_bio .bio h2
{
    margin-top: 20px;
    font-size: 22px;
    line-height: 36px;
}



@media only screen and (max-width: 500px) {

    .panel_projects
    {
        padding-top: 0;
    }

    .panel_bio
    {
        margin-top: 0;
    }

    .panel_bio .bio h1
    {
        font-size: 24px;
        line-height: 32px;
    }

    .panel_bio .bio h2
    {
        font-size: 16px;
        line-height: 24px;
    }
}
