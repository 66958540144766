body
{
    color: #223;
    font-family: Roboto, Arial, sans-serif;
    text-align: left;
}

header
{
    padding-top: 200px;
    text-align: center;
    height: 400px;
    background: linear-gradient(90deg, #9d9cc7, #ade6f7);

}

header h1
{
    color: #fff;
    margin-top: 20px;
    font-size: 42px;
    font-weight: normal;
    font-family: Merriweather, Courgette, Arial, serif;
}

header h1 span
{
   color: #b9ebff;
}

header .tagline
{
    color: #445;
    font-weight: bold;
    letter-spacing: 1px;

}

header .location
{
    font-family: Handlee, Arial, sans-serif;
    margin-top: 4px;
    color: #fff;
    font-weight: bold;
}

@media only screen and (max-width: 500px) {

    header h1
    {
        font-size: 38px;
    }

    header .tagline
    {
        font-size: 14px;
    }
}



h1, h2, h3, h4
{
    margin-top: 0;
    margin-bottom: 0;
}

p
{
    margin-top: 10px;
    margin-bottom: 10px;
}


button
{
  color: #fff;
  border: 0;
  font-weight: bold;
  text-align: center;
  border-radius: 4px;
  font-family: Roboto, Arial, sans-serif;
  background-color: #08f;
  padding: 8px 12px;
  outline: 0;
  cursor: pointer;
}


.cta
{
  margin-top: 10px;
  font-size: 24px;
  font-family: Asap, Robot, Arial, sans-serif;
  box-shadow: 1px 1px 4px #888888;
  padding: 12px 16px;
  border-radius: 10px;
  transition: background-color 0.5s, border-radius 0.5s, box-shadow 0.5s;
}

.cta:hover
{
    border-radius: 16px;
    box-shadow: #222222 1px 1px 4px;
}

.button2
{
    background-color: #08f;
}

.button3
{
    background-color: #f80;
}

.button4
{
    background-color: #52d0c4;
}

button.button3.cta:hover
{
    /* background-color: #ff6a00; */
}


@media only screen and (max-width: 500px) {

    .cta
    {
        font-size: 18px;
    }
}



.panel_navigation
{
    padding-top: 2px;
    padding-bottom: 20px;
    display: none;
}



.list_navigation li
{
    float: left;
}

.list_navigation li a
{
    color: #fff;
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 2px;
    padding-top: 4px;
    text-align: center;
    font-family: Asap, Arial, sans-serif;
    font-weight: bold;

}

.list_navigation li a.active
{
    border-bottom: 2px solid #bce6fd
}

.panel_footer
{
    padding-top: 20px;
    background-color: #50585f;
    text-align: center;
}

.panel_footer a
{
    color: #c1e2ff;
}

.panel_copyright
{
    margin-top: 20px;
    background-color: #444c52;
    padding: 12px 0;
    font-size: 12px;
}

@media only screen and (max-width: 500px) {

    .panel_footer
    {
        padding-top: 0;
        margin-top: 0;
    }

    .panel_footer h1
    {
        font-size: 22px;
    }
}



/* projects */

.panel_project
{
    width: calc(100% - 40px);
    margin-right: 20px;
    margin-left: 20px;
    transition: box-shadow 1s;
}



.panel_project:hover
{
    box-shadow: #999 1px 1px 6px;
}

.project-details
{
    float: left;
    width: 55%;
    margin-bottom: 40px;
    text-align: right;
}

.project-summary
{
    float: right;
    width: 40%;
    margin-top: 10px;
}

.project-summary p
{
    margin-bottom: 24px;
}

.project-link
{
    margin-top: 10px;
    font-size: 18px;
}


@media only screen and (max-width: 500px) {

    .panel_project
    {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }


    .project-details,
    .project-summary
    {
        text-align: center;
        width: 75%;
        margin-left: auto;
        margin-right: auto;
        float: none;
    }

    .project-details
    {
        padding-top: 10px;
    }

    .project-summary
    {
        padding-bottom: 30px;
    }


}



/* colors */

.contentBox-periwinkle
{
    color: #888;
    background-color: #ecf7ff;
}

.contentBox-pink
{
    background-color: #f6546c;
}

.contentBox-grape
{
    background-color: #7861ea;
}

.contentBox-slate
{
    background-color: #aec1d2;
}

.white
{
    color: #fff;
}