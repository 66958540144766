body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



a
{
  color: #08f;
  text-decoration: none;
}

ul li
{
  list-style: none;
}


.skew-panel
{
    position: relative;
    padding-bottom: 20px;
}


.skew-background
{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: skewY(3deg);
    transform-origin: top right
}


.skew-background-reverse
{
    transform: skewY(-3deg);
    transform-origin: top left;
}

@media only screen and (max-width: 500px) {

    .skew-background
    {
        transform: skewY(6deg);
    }
}


.content-width
{
  max-width: calc(1024px - 8px);
  margin-left: auto;
  margin-right: auto;
  padding-left: 4px;
  padding-right: 4px;
}


.verticalPadding { padding-top: 40px; padding-bottom: 40px; }

.float { float: left; }
.float-right { float: right; }
.clear { clear: both; }

.relative { position: relative; }

.nowrap { white-space: nowrap; }

.text-shadow { text-shadow: 1px 1px 8px #222; }